@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@font-face {
  font-family: "Poppins", sans-serif;
  src: local("Poppins-Regular"),
    url(/public/fonts/Poppins-Regular.ttf) format("opentype");
}
.show {
  position: relative;
  width: 100%;
}

.hide {
  display: none;
}

.middle {
  background-color: #effdef;
}

.big {
  background: linear-gradient(
    257deg,
    #e5ffea 0%,
    rgba(224.19, 255, 229.12, 0) 100%
  );
  background-size: cover;
  height: 600px;
  padding: 23px 100px 23px 120px;
  display: flex;

  position: relative;
}

.e-image {
  margin-top: 2vh;
  margin-left: 30%;
}

.n-search {
  display: block;
}

.element {
  height: 20vh;
}

@screen sm {
  .element {
    height: 40vh;
  }

  .e-image {
    margin-top: 3vh;
    margin-left: 30%;
  }
}

@screen md {
  .element {
    height: 50vh;
  }

  .e-image {
    margin-top: 4vh;
    margin-left: 30%;
  }

  .big1 {
    background-color: white;
    background-size: cover;
    padding: 5vh 2% 5vh 2%;
    display: flex;
  }
}

@screen lg {
  .element {
    height: 60vh;
  }

  .e-image {
    margin-top: 5vh;
    margin-left: 30%;
  }

  .hide {
    display: block;
  }

  .n-search {
    display: none;
  }

  .show {
    width: 50%;
  }
}
*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

label {
  font-size: 1.2rem;
  color: #656262;
}

html,
body {
  height: 100%;
  width: 100%;
}

.form_container {
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}

.form_container > form {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.form_container div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.form_container input {
  border: none;
  padding: 0.5rem;
  border-bottom: 1px solid gray;
  font-size: 1.1rem;
  outline: none;
}

.form_container input::placeholder {
  font-size: 0.9rem;
  font-style: italic;
}

.home_page {
  height: 100vh;
  width: 100vw;
  background: #000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 3rem;
  flex-direction: column;
  gap: 1rem;
}

@media only screen and (max-width: 1200px) {
  .home_page {
    font-size: 1.5rem;
  }
  .home_page button {
    padding: 0.6rem 1rem;
    font-size: 1.5rem;
  }
}

.disable-mobile {
  background: #e5ffea;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  font-size: 72px;
  color: rgb(36, 113, 71);
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ant-drawer-body {
  padding: 0 !important;
}

@media only screen and (max-width: 768px) {
  .disable-mobile {
    display: flex;
  }
}

.selectUnit .ant-select-selector {
  border-radius: 0 8px 8px 0;
}


div.ant-upload {
  width: 100%;
}
div.ant-upload-select {
  width: 100%;
}

.page-title {
  color: #247147;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

.input-label {
  color: #AFAFAF;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}

.input-box {
  height: 40px;
  border-radius: 8px;
  background-color: white;
}

.front-page-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 300px;
  background-color: #247147;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.table-header {
  @apply text-center leading-4 text-[0.65rem] font-medium text-black uppercase tracking-wider;
  @apply p-1 bg-[#E4ECE5] h-[70px];
}

.table-data {
  @apply text-center leading-5 text-gray-900;
  @apply p-1 bg-white text-[0.65rem];
}
